.visitors-section{
    position:relative; 
    bottom:0;
    padding: 20px;
}

.visitors-section .sub-title{
    width: 100%;
}

#visits{
    border: 1px solid gray;
}

.counter{
     background-color: #ffffff;
     margin: 10px 0px 10px 0px;
}