.instagram-section{
    padding: 20px;
}

.instagram-section .header{
    width: 100%;
}

.instagram-section .sub-title{
    float: left;
}

.instagram-section button{
    float: right;
}

.instagram-gallery {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px 5px 0px;
}

.instagram-item {
    -webkit-box-flex: 0;
    flex: 0 0 calc(100% / 4 - 10px );
    margin: 5px;
    display: block;
    position: relative;
 
}

.instagram-image {
    width:100px;
    height:100px;
    object-fit:cover;
}

.pagination {
  display: block;
  margin: 0 auto;
  padding: 2em;
}



